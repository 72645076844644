<template>
  <div class="mPoetry">
    <div class="mPoetry-head">
      <div class="head-left">
        <h1>同步写作300天</h1>
        <p>每日一句，每周一段，每月一篇</p>
        <ul>
          <li>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile.png" />
            <span>同步语文教材、贴合考纲</span>
          </li>
          <li>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile.png" />
            <span>分年段、300节在线课程、26册配套实体教材</span>
          </li>
          <li>
            <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/icon-mobile.png" />
            <span>每月1次一对一写作精点评、限时赠送4次周点评</span>
          </li>
        </ul>
        <button class="signUp-btn" @click="signUp()">立即报名</button>
      </div>
      <img
        src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/blue-icon.png"
        class="head-img"
      />
    </div>
    <div class="video-contain">
      <!-- <div class="video-box">
        <img
          @click="videoPlay"
          v-if="showVideoBg"
          class="video-bg"
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/composition-videobg.jpg"
          alt
        />
        <video
          :class="{hide: showVideoBg}"
          ref="compositionVideo"
          class="video"
          controls
          name="media"
          controlslist="nodownload"
        >
          <source src="https://xcx.alicdn2.hexiaoxiang.com/web_static/zh_qzw.mp4" type="video/mp4" />
        </video>
      </div> -->
      <div class="course-brief">
        <h2>
          课程
          <span>介绍</span>
        </h2>
        <p>
          河小象大语文携手北师大、浙大文学硕博士团队，设计了分年段的同步写作课程——精选教材知识点，贴合考纲内容，针对性提升写作力：
          课程共300节课，每课8分钟，包含5分钟讲课和3分钟训练；
          课程分年段设置，低段适合一二年级，中段针对三四年级。
          课程按好词、好句专题（低级）/写人、叙事、写景、状物等专题（高级）分类，区分和总结不同题材的写作技巧。
          课程设置了6大板块，5大练习，趣味学习和上升式练笔相结合。
        </p>
      </div>
    </div>
    <div class="brief-list">
      <div class="brief-box">
        <div class="brief-left">
          <h3>写作上的“三座大山”</h3>
          <ul>
            <li>
              <span class="point pointTwo"></span>
              <span class="brief-content">
                <span class="content-tit">没话写：</span>
                抄写的好词好句一大堆，但是用不上啊！
              </span>
            </li>
            <li>
              <span class="point pointTwo"></span>
              <span class="brief-content">
                <span class="content-tit">不愿写：</span>
                我家孩子最怕写作文
                了，写作这件事太枯燥了！
              </span>
            </li>
            <li>
              <span class="point pointTwo"></span>
              <span class="brief-content">
                <span class="content-tit">随意写：</span>
                孩子写作毫无章法，
                想到什么些什么，还容易离题
              </span>
            </li>
          </ul>
        </div>
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/compositon1.png"
          class="brief-img"
        />
      </div>
      <div class="brief-box">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/compositon2.png"
          class="brief-img"
        />
        <div class="brief-left">
          <h3>课程效果</h3>
          <ul>
            <li>
              <span class="point"></span>
              <span class="brief-content">原创动画情景剧导入；</span>
            </li>
            <li>
              <span class="point"></span>
              <span class="brief-content">延伸剧情讲解素材和技巧；</span>
            </li>
            <li>
              <span class="point"></span>
              <span class="brief-content">每课设置多个互动环节；</span>
            </li>
            <li>
              <span class="point"></span>
              <span class="brief-content">中外名著中遴选的与主题相关的句段；</span>
            </li>
            <li>
              <span class="point"></span>
              <span class="brief-content">课后经典诵读提升语感；</span>
            </li>
            <li>
              <span class="point"></span>
              <span class="brief-content">课后摘抄好词好句</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="brief-box">
        <div class="brief-left">
          <h3>AI智能诵读打分</h3>
          <ul>
            <li>
              <span class="brief-content">我们从完整度、流利度声韵母、流利度、声调，四个方面检测对孩子诵读进行评分，让孩子更有目的性的诵读训练。</span>
            </li>
          </ul>
        </div>
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/composition3.png"
          class="brief-img"
        />
      </div>
    </div>
    <!-- <div class="course-arrange">
      <h2>
        课程
        <span>安排</span>（部分）
      </h2>
      <div class="table-head">
        <div class="time">课时</div>
        <div class="from">选自</div>
        <div class="course">课程</div>
      </div>
      <div class="arrange-table">
        <div class="table-list">
          <div class="time">第一讲</div>
          <div class="from">《史记·陈涉世家》</div>
          <div class="course">鸿鹄之志</div>
        </div>
        <div class="table-list">
          <div class="time">第二讲</div>
          <div class="from">《史记·淮阴侯列传》</div>
          <div class="course">多多益善</div>
        </div>
        <div class="table-list">
          <div class="time">第三讲</div>
          <div class="from">《舟过安仁》</div>
          <div class="course">舟过安仁</div>
        </div>
        <div class="table-list">
          <div class="time">第四讲</div>
          <div class="from">《史记·廉颇蔺相如列传》</div>
          <div class="course">完璧归赵</div>
        </div>
        <div class="table-list">
          <div class="time">第五讲</div>
          <div class="from">《宿建德江》</div>
          <div class="course">宿建德江</div>
        </div>
        <div class="table-list">
          <div class="time">第六讲</div>
          <div class="from">《史记·高祖本纪》</div>
          <div class="course">约法三章</div>
        </div>
        <div class="table-list">
          <div class="time">第七讲</div>
          <div class="from">《淮南子·兵训传》</div>
          <div class="course">声东击西</div>
        </div>
      </div>
      <div class="table-footer">共365讲</div>
    </div> -->
    <!-- 课程安排 -->
    <mCourse :midCourse="midCourse" :primaryCourse="primaryCourse" class="cur-blue" :flag='1'></mCourse>
      <button class="more-btn" @click="signUp">报名查看更多课程</button>
       <!-- 配套教材 -->
    <div class="book">
      <div class="book-contain">
        <h2>配套教材</h2>
        <div class="book-btn">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-btn1.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-btn2.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-btn3.png" >
        </div>
       <div class="book-img">
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-book1.png" >
          <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/write-text-book2.png" >
       </div>
      </div>
    </div>
    <!-- 上课流程 -->
    <div class="process">
      <h2>
        上课
        <span>流程</span>
      </h2>
      <img src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/composition-process.png" />
    </div>
    <!-- 我们承诺 -->
    <div class="promise">
      <h2>
        我们
        <span>承诺</span>
      </h2>
      <div class="promise-box">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/buget.png"
          class="buget"
        />
        <div>
          <h4>无忧退款</h4>
          <p>对课程不满意，对上课老师不满意，对效果不满意，开课15天内可以提出退款要求</p>
        </div>
      </div>
      <div class="promise-box">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/serve.png"
          class="serve"
        />
        <div>
          <h4>服务承诺</h4>
          <p>根据孩子学习进度实时跟踪监测，专属助教全程提供五星服务，为家长解读各种问题</p>
        </div>
      </div>
      <div class="promise-box">
        <img
          src="https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/teach.png"
          class="teach"
        />
        <div>
          <h4>师资承诺</h4>
          <p>所有老师均经过层层筛选与培训，擅长语文教育培训</p>
        </div>
      </div>
    </div>
    <!-- 家长反馈 -->
    <mFeedBack :list="feedBackList" colorStyle="#56C1FF"></mFeedBack>
    <mTool :downloadShow="showDownload"></mTool>
    <receiveCourse :receiveShow="receiveShow" @closeMask="receiveShow=false"></receiveCourse>
  </div>
</template>
<script>
import mFeedBack from "@/components/mFeedBack";
import mTool from "@/components/mTool";
import receiveCourse from "../components/receiveCourse";
import mCourse from "../components/mCourse";
export default {
  data() {
    return {
      receiveShow: false,
      showVideoBg: true,
      showDownload: false,
      feedBackList: [
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback1.png",
          name: "王诺彦",
          content: [
            "平均三天上墙一次，现在一下子语文成绩很好了，前三名了，谢谢老师！"
          ]
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback2.png",
          name: "珑月",
          grade: "",
          content: [
            "我女儿读了葛朗台的人物描写，为了想要得高分读了好几遍，最后都背下来了！"
          ]
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback3.png",
          name: "薇笑",
          grade: "",
          content: ["通过7天的学习，孩子的确有收获，我和他都很喜欢轻作文课！"]
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback4.png",
          name: "龚苹",
          grade: "",
          content: ["这个课真的很好，老师的知识量非常大，我家娃很喜欢！"]
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback5.png",
          name: "唐诗涵",
          grade: "",
          content: [
            "今天的课她读了好多遍都读不好，我都不耐烦了她还不死心。她真的是超爱轻作文这个环节的，希望孩子能一直坚持下去。"
          ]
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback6.png",
          name: "汶汶",
          grade: "",
          content: [
            "本来以为效果一般，结果我考了一下孩子，发现他记住的真不少！期待之后的效果！"
          ]
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback7.png",
          name: "余霞",
          grade: "",
          content: [
            "现在学到第二本了，孩子会记住上课时间，主动学习、交作业，尤其是答题环节，孩子特别喜欢！"
          ]
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback8.png",
          name: "小脚",
          grade: "",
          content: [
            "孩子特别喜欢河小象的课程，每天都吵着要上年糕姐姐的课，十分感谢河小象！"
          ]
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback9.png",
          name: "安然",
          grade: "",
          content: [
            "最近孩子的写作水平进步特别大，看了他的试卷之后简直出乎我的意料，连班主任都夸奖他。"
          ]
        },
        {
          avatar:
            "https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/article-feedback10.png",
          name: "全",
          grade: "",
          content: [
            "孩子以前读的书有拼音，孩子依赖拼音不看汉字。轻作文的课很适合一年级的孩子，便于孩子词汇量增大。"
          ]
        }
      ],
        primaryCourse: [
        {
          week: ["第一课"],
          classic: "《帮吉吉鼠搬家》",
          knowledge: ["量词"]
        },
        {
          week: ["第二课"],
          classic: "《春天的田野》",
          knowledge: ["带颜色的成语"]
        },
        {
          week: ["第三课"],
          classic: "《快乐运动会》  ",
          knowledge: ["人物动作描写  "]
        },
        {
          week: ["第四课"],
          classic: "《绿色的太阳》",
          knowledge: ["反问句"]
        },
        {
          week: ["第五课"],
          classic: "《秋游的乐趣》   ",
          knowledge: ["描写季节的成语 "]
        },
        {
          week: ["第六课"],
          classic: "《陪懒懒猪买衣服》",
          knowledge: ["“的”的用法  "]
        },
        {
          week: ["第七课"],
          classic: "《我们去野炊吧》",
          knowledge: [" 第一周总结课"]
        },
        {
          week: ["第八课"],
          classic: "《你会找不同吗》",
          knowledge: [" 有趣的反义词  "]
        },
         {
          week: ["第九课"],
          classic: "《一次数学考试过后》",
          knowledge: ["人物心理描写"]
        },
        {
          week: ["第十课"],
          classic: "《一起去购物》",
          knowledge: ["动词"]
        },
        {
          week: ["第十一课"],
          classic: "《我们不一样》",
          knowledge: ["因为……所以……"]
        },
        {
          week: ["第十二课"],
          classic: "《森林公园怎么走》",
          knowledge: ["什么是方位词"]
        },
        {
          week: ["第十三课"],
          classic: "《我怎么不知道下雪了》",
          knowledge: ["常用的标点符号"]
        },
         {
          week: ["第十四课"],
          classic: "《登山历险记》",
          knowledge: ["第二周总结课 "]
        },
      ],
      midCourse: [
        {
          week: ["第一课"],
          classic: "《爸爸抓小偷》",
          knowledge: ["如何描写人的行为"]
        },
        {
          week: ["第二课"],
          classic: "《帮妈妈洗菜》",
          knowledge: ["如何生动地写出人物的动作"]
        },
        {
          week: ["第三课"],
          classic: "《肚子装不下了》",
          knowledge: ["如何写一个贪吃鬼"]
        },
        {
          week: ["第四课"],
          classic: "《香香的大餐》",
          knowledge: ["如何写一个小书迷"]
        },
        {
          week: ["第五课"],
          classic: "《我的海报不见了！》",
          knowledge: ["如何写一个满头大汗的人"]
        },
        {
          week: ["第六课"],
          classic: "《爷爷的钥匙去哪儿了？》",
          knowledge: ["如何写一个健忘的人"]
        },
        {
          week: ["第七课"],
          classic: "《他在做什么？》",
          knowledge: ["第一周总结课：写一写人物动作"]
        },
        {
          week: ["第八课"],
          classic: "《看望爷爷》",
          knowledge: ["如何描写人物的性格"]
        },
        {
          week: ["第九课"],
          classic: "《哪吒今天烧坏了什么？》",
          knowledge: ["如何写一个调皮的人"]
        },
        {
          week: ["第十课"],
          classic: "《一只鸡蛋引发的争论》",
          knowledge: ["如何写一个口齿伶俐的人"]
        },
         {
          week: ["第十一课"],
          classic: "《大嗓门的志愿者阿姨》",
          knowledge: ["如何写一个热心的人"]
        },
        {
          week: ["第十二课"],
          classic: "《与小机灵鬼的初次见面》",
          knowledge: ["如何写一个机灵的人"]
        },
        {
          week: ["第十三课"],
          classic: "《他是怎样的性格？》",
          knowledge: ["第二周总结课：写一写人物性格"]
        },
        {
          week: ["第十四课"],
          classic: "《香香的困惑》",
          knowledge: ["口语交际：如何向别人介绍自己"]
        },
      ]
    };
  },
  components: {
    mFeedBack,
    mTool,
    receiveCourse,
    mCourse
  },
  methods: {
    signUp() {
      // window.location.href = "https://yw.hexiaoxiang.com/goods/purchase.html#/?productId=12";
      this.receiveShow = true;
    },
    // 播放视频
    videoPlay() {
      this.showVideoBg = false;
      let video = this.$refs.compositionVideo;
      let that = this;
      video
        .play()
        .then(res => {
          video.addEventListener("pause", () => {
            that.showVideoBg = true;
          });
          video.addEventListener('seeking',()=>{
            that.showVideoBg=false
          })
        })
        .catch(err => {});
    }
  }
};
</script>
<style lang="scss" scoped>
.mPoetry {
  .mPoetry-head {
    background: url(https://xcx.alicdn2.hexiaoxiang.com/web_static/new_hx_web/blue-bg.png)
      no-repeat;
    background-size: 100% 100%;
    padding: 0.853rem 0.48rem 0;
    position: relative;
    height: 7.893rem;
    .head-left {
      color: #fff;
      text-align: left;
      h1 {
        font-size: 0.72rem;
        font-weight: bold;
      }
      p {
        font-size: 0.373rem;
        margin: 0.227rem 0 0.7rem;
      }
      ul {
        font-size: 0.32rem;
        li {
          line-height: 0.72rem;
          img {
            height: 0.093rem;
          }
        }
      }
      button {
        width: 3.2rem;
        height: 0.8rem;
        border-radius: 0.4rem;
        background: linear-gradient(
          270deg,
          rgba(248, 120, 51, 1) 0%,
          rgba(255, 139, 53, 1) 100%
        );
        color: #fff;
        font-size: 0.373rem;
        font-weight: bold;
        margin-top: 0.533rem;
      }
    }
    .head-img {
      width: 5.067rem;
      height: 3.587rem;
      position: absolute;
      top: 5.2rem;
      right: 0.16rem;
    }
  }
  .video-contain {
    display: flex;
    padding: .8rem 0.32rem;
    align-items: center;
    .video-box {
      // position: relative;
      // padding-bottom: .453rem;
      .video-bg {
        height: 4.693rem;
        display: block;
      }
      .video {
        height: 4.693rem;
      }
      .hide {
        display: none;
      }
    }
  }

  .course-brief {
    margin-left: 0.267rem;
    h2 {
      font-size: 0.6rem;
      color: #323232;
      font-weight: bold;
      text-align: left;
    }
    p {
      font-size: 0.373rem;
      color: #323232;
      line-height: 0.533rem;
      text-align: left;
      margin-top: 0.267rem;
    }
  }

  .brief-list {
    background-color: #f6f6f9;
    padding-bottom: 0.267rem;
    .brief-box {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      padding: 1rem 0;
      &:not(:last-child) {
        margin-bottom: 0.267rem;
      }
      &:first-child .brief-left {
        width: 6rem;
        margin-right: 0.3rem;
      }
      &:nth-child(2) .brief-left {
        width: 4.36rem;
        margin-left: 0.853rem;
        h3 {
          margin-bottom: 0.1rem;
        }
      }
      &:nth-child(3) .brief-left {
        width: 4.64rem;
        margin-right: 0.773rem;
      }
      .brief-left {
        text-align: left;
        h3 {
          color: #444144;
          font-size: .6rem;
          font-weight: bold;
          margin-bottom: 0.347rem;
        }
        ul {
          font-size: 0.373rem;
          li {
            display: flex;
            align-items: center;
            line-height: 0.533rem;
            .point {
              background-color: #2fa4ff;
              width: 0.08rem;
              height: 0.08rem;
              display: block;
            }
            .pointTwo {
              margin-top: -0.6rem;
            }
            .brief-content {
              color: #706c70;
              margin-left: 0.133rem;
              .content-tit {
                font-weight: bold;
              }
            }
          }
        }
      }
      .brief-img {
        width: 3.227rem;
      }
    }
  }
  .course-arrange {
    h2 {
      color: #444144;
      font-size: 0.587rem;
      margin: 0.573rem 0.48rem;
      span {
        color: #56c1ff;
      }
    }
    .table-head {
      display: flex;
      justify-content: center;
      margin-bottom: 0.053rem;
      div {
        background-color: #56c1ff;
        color: #fff;
        font-weight: bold;
        font-size: 0.4rem;
        height: 0.747rem;
        line-height: 0.747rem;
      }
      .time {
        width: 2.453rem;
        border-radius: 0.16rem 0 0 0;
      }
      .from {
        width: 4rem;
        margin: 0 0.067rem;
      }
      .course {
        width: 2.453rem;
        border-radius: 0 0.16rem 0 0;
      }
    }
    .arrange-table {
      display: flex;
      align-items: center;
      flex-direction: column;
      .table-list {
        display: flex;
        color: #444144;
        font-size: 0.32rem;
        margin-bottom: 0.053rem;
        div {
          height: 0.747rem;
          line-height: 0.747rem;
          background-color: #d5f2ff;
        }
        &:nth-child(2n + 1) div {
          background-color: #ecf9ff;
        }
        .time {
          width: 2.453rem;
        }
        .course {
          width: 2.453rem;
        }
        .from {
          width: 4rem;
          margin: 0 0.053rem;
        }
      }
    }
    .table-footer {
      background-color: #47acff;
      font-size: 0.4rem;
      color: #fff;
      font-weight: bold;
      width: 9.04rem;
      height: 0.747rem;
      border-radius: 0 0 0.16rem 0.16rem;
      line-height: 0.747rem;
      margin: 0 auto;
    }
  }
  .process {
    padding-bottom: 0.667rem;
    h2 {
      font-size: 0.587rem;
      color: #444144;
      font-weight: bold;
      margin: 1.373rem 0 0.6rem;
      span {
        color: #47acff;
      }
    }
    img {
      height: 6.307rem;
    }
  }
  .book{
     padding-bottom: 0.667rem;
     background: #56C1FF;
     padding-top: .64rem;
     h2{
      font-size: 0.587rem;
      color: #fff;
      font-weight: bold;
      margin-bottom: .213rem;
     }
     .book-btn{
       display: flex;
       justify-content: center;
       img{
         height: .667rem;
         &:not(:last-child){
           margin-right: .267rem;
         }
       }
     }
     .book-img{
       img{
         height: 8.373rem;
         margin-top: .64rem;
       }
     }
  }
  .promise {
    background-color: #f6f6f9;
    padding: 0.627rem 0 0.667rem;
    h2 {
      font-size: 0.587rem;
      color: #444144;
      font-weight: bold;
      margin-bottom: 0.533rem;
      span {
        color: #56c1ff;
      }
    }
    .promise-box {
      width: 9.04rem;
      height: 2.133rem;
      border-radius: 0.213rem;
      box-shadow: 0px 32px 50px 0px rgba(161, 161, 172, 0.18);
      background: #fff;
      margin: 0 auto;
      display: flex;
      margin-bottom: 0.4rem;
      align-items: center;
      img {
        margin: 0 0.253rem 0 0.427rem;
      }
      .buget {
        height: 0.867rem;
      }
      .teach {
        height: 1.067rem;
      }
      .serve {
        height: 1.027rem;
      }
      div {
        text-align: left;
        width: 6.64rem;
        padding-left: 0.253rem;
        border-left: 1px dashed #eeeeee;

        h4 {
          color: #444144;
          font-size: 0.427rem;
          font-weight: bold;
          margin-bottom: 0.213rem;
        }
        p {
          font-size: 0.32rem;
          color: #888888;
        }
      }
    }
  }
  .m-feedback {
    padding: 0.667rem 0 0.933rem;
  }
  .more-btn{
    width: 4.2rem;
    height: 0.8rem;
    border-radius: 0.4rem;
    background: -webkit-gradient(linear, right top, left top, from(#f87833), to(#ff8b35));
    background: linear-gradient(270deg, #f87833 0%, #ff8b35 100%);
    color: #fff;
    font-size: 0.373rem;
    font-weight: bold;
    margin:0 auto 1.067rem;
  }
}
</style>
